<template>
  <div>
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Collection жагсаалт</h2>
        </el-col>
        <el-col :span="12">
          <div class="actions text-right">
            <router-link to="/add-collection">
              <el-button type="success" class="item">Нэмэх</el-button>
            </router-link>
          </div>
        </el-col>
      </el-row>
    </header>
    <div class="panel tabled">
      <el-tabs v-model="activeTabStatus">
        <el-tab-pane label="Бүгд" name="all">
          <div class="panel-content">
            <div class="pl20 pr20 table filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="12">
                  <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="onSearch">
                    <el-button slot="prepend" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4">
                   <el-select v-model="selectStatus" @change="filterStatus" placeholder="Төлөв сонгох">
                    <el-option
                      v-for="(status, index) in statusSelect"
                      :key="index"
                      :label="status.label"
                      :value="status.value"
                      >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8" :offset="4" class="text-right">
                  <el-dropdown  @command="handleSort">
                  <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in dropdownDataFilter" :key="indexFilter">{{filter.label}}</el-dropdown-item>
                       </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
              <div class="panel-content">
              <el-table
              :data="collectionData"
              @cell-click="sellClick"
              v-loading="isLoading"
              style="width: 100%; margin-top:20px">
              <el-table-column
              prop="name_mon"
              label="Нэр">
              </el-table-column>
              <el-table-column
              label="Зураг">
                <template slot-scope="scope">
                  <el-row :gutter="10" align="middle" type="flex">
                     <el-col :span="8">
                      <el-image
                        class="image-holder"
                        :src="scope.row.collection_pic"
                        fit="contain">
                      </el-image>
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column
              prop="sort"
              label="Эрэмбэ">
              </el-table-column>
              <el-table-column label="Төлөв">
              <template slot-scope="scope">
              <el-tag v-if="scope.row.status === 'active'" size="smill" type="success">Идэвхтэй</el-tag>
              <el-tag v-else size="smill" type="warning">Идэвхгүй</el-tag>
              </template>
              </el-table-column>
              </el-table>
              <el-pagination
              @current-change = "curentPageHandler"
              @size-change = "sizeChangeHandler"
              class="text-right mt10"
              background
              :current-page.sync="currentPage"
              :page-size="pageSize"
              :page-sizes="[5, 20, 50, 100]"
              layout="total, sizes, prev, pager, next"
              :total="totalCount">
            </el-pagination>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import services from '../../../helpers/services'
export default {
  components: {},

  mounted () {
    var from = this.pageSize * (this.currentPage - 1)
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      from = this.pageSize * (incomingPage - 1)
      size = incomingSize
    }
    this.getCollection(from, size, this.search)
  },

  data () {
    return {
      page: '',
      activeTabStatus: 'all',
      type: '',
      isLoading: false,
      selectStatus: '',
      collectionData: [],
      totalCount: 0,
      currentPage: 1,
      loading: false,
      search: '',
      pageSize: 20,
      statusSelect: [{
        value: '',
        label: 'Бүгд'
      }, {
        value: 'active',
        label: 'Идэвхтэй'
      }, {
        value: 'inactive',
        label: 'Идэвхгүй'
      }],
      dropdownDataFilter: [
        {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }
      ]
    }
  },

  methods: {
    onSearch () {
      this.getCollection(
        this.pageSize * (this.currentPage - 1),
        this.pageSize,
        this.search
      )
    },
    sellClick (row, column, cell) {
      this.$router.push(
        {
          name: 'collectionEdit',
          params: {
            id: row.collection_id
          }
        }
      )
    },

    sortMethods (method, data) {
      if (method === 'firstChar') {
        data.sort((a, b) => a.name_mon.localeCompare(b.name_mon))
      } else if (method === 'lastChar') {
        data.sort((a, b) => b.name_mon.localeCompare(a.name_mon))
      } else if (method === 'newest') {
        data.sort((a, b) => b.created_at.localeCompare(a.created_at))
      } else if (method === 'oldest') {
        data.sort((a, b) => a.created_at.localeCompare(b.created_at))
      }
      return data
    },
    filterStatus () {
      this.currentPage = 1
      this.getCollection(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.field, this.sort)
    },

    handleSort (data) {
      this.search = ''
      this.collectionData = this.sortMethods(data, this.collectionData)
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({
        name: 'collectionList',
        query: {
          page: this.currentPage,
          size: this.pageSize,
          search: this.search,
          fillterPage: this.page
        }
      })
      this.getCollection(
        this.pageSize * (this.currentPage - 1),
        this.pageSize,
        this.search,
        this.page
      )
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({
        name: 'collectionList',
        query: {
          page: item,
          size: this.pageSize,
          search: this.search,
          fillterPage: this.page
        }
      })
      this.getCollection(
        this.pageSize * (this.currentPage - 1),
        this.pageSize,
        this.search,
        this.page
      )
    },

    getCollection (from, size, search, page) {
      const query = '?size=' + size + '&from=' + from + '&status=' + this.selectStatus + '&search_text=' + search
      this.isLoading = true
      services.getCollection(query).then((response) => {
        this.collectionData = response.data
        this.totalCount = response.total
        this.isLoading = false
      })
    }
  }
}
</script>
